export const EMPTY_STRING = "";
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const hideFinishButton = () => { 
    let element = document.querySelector('[data-shepherd-step-id="awesome-multi-steps-btn-next"]'); 
        element?.setAttribute("hidden", "true");
}

export const isStringNullOrEmpty = (data: string) => {
    return data === null || data === undefined || data === "";
}

export const isGuidNullOrEmpty = (data: string) => {
    return data == null || data == undefined || data == EMPTY_GUID;
}